import React, {useState} from 'react'

import {Styles} from '../../components/Table_Style'
import {CustomerPendingDeposit_Filter} from './components/Cust-Pending-Deposit-Filter'
import {OverlayTrigger} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import popover from '../../components/Popover'
import CustPendingDepDetails_Modal from './Cust-Pending-Deposit-Details'
import sortTableDataWithHeaderBtn from '../../components/SortFormDataWithHeaderBtn'
import sortFormDataIcon from '../../components/SortFormDataIcon'
import {
  Column,
  useTable,
  useBlockLayout,
  useResizeColumns,
  useFlexLayout,
  useFilters,
  usePagination,
} from 'react-table'

const CustPendingDepositList: React.FC = () => {
  //States
  const [formData, setFormData] = useState([])
  const [selectedRow, setSelectedRow] = useState<any>({
    requestCount: '',
    customerCd: '',
  })
  const [sortHeader, setSortHeader] = useState({
    sortColumn: '',
    sortSequence: '',
  }) //asc or desc

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  //Table::START
  interface Data {
    requestCount: string
    customerCd: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'Customer Code',
        accessor: 'customerCd',
        disableFilters: true,
      },
      {
        // Header: 'Pending Deposit Count',
        Header: () => {
          return (
            <div
              className='p-0 d-flex flex-column'
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                sortTableDataWithHeaderBtn(
                  formData,
                  setFormData,
                  sortHeader,
                  setSortHeader,
                  'requestCount'
                )
              }}
            >
              <div>Pending Deposit Count</div>
              <div className='d-flex align-items-center justify-content-center mt-2'>
                {sortFormDataIcon(sortHeader, 'requestCount')}
              </div>
            </div>
          )
        },
        accessor: 'requestCount',
        disableFilters: true,
      },
      {
        Header: 'Action',
        disableFilters: true,
        accessor: (row: any) => {
          return (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              data-bs-toggle='modal'
              data-bs-target='#custPendingDepDetailsModal'
              onClick={() => setSelectedRow(row)}
            >
              <OverlayTrigger trigger='hover' placement='top' overlay={popover('See Details')}>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen038.svg' className='svg-icon-3' />
                </div>
              </OverlayTrigger>{' '}
            </div>
          )
        },
        width: 100,
      },
    ],
    [formData]
  )

  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFilters,
      usePagination,
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <CustomerPendingDeposit_Filter setFormData={setFormData} />
      <Styles>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </Styles>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      <CustPendingDepDetails_Modal selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      {/* Pagination END */}
    </div>
  )
}

export default CustPendingDepositList
