import axios from 'axios'

export default function CustomerPendingBulkReject(
  rowSelection: any,
  selectedRowData: any,
  refresh: boolean,
  setRefresh: any,
  setRowSelection: any
) {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  const selectedIndices = Object.keys(rowSelection).map((key) => parseInt(key, 10))
  const filteredSelectedRowData = selectedRowData
    .filter((_: any, index: number) => selectedIndices.includes(index))
    .map((row: any) => row.id)

  if (filteredSelectedRowData.length === 0) {
    alert('No rows selected')
    return
  }

  axios
    .post(`${SISPAY_API}/cash/depositrequest/bulkreject`, {
      request: {
        session: session,
        depositReqs: filteredSelectedRowData,
        approvalRejectionRemark: 'FAKE-SPAMMER',
      },
    })
    .then(function (response) {
      if (response.data.code == 200) {
        alert(response.data.message)
        setRefresh(!refresh)
        setRowSelection({})
      } else {
        alert(response.data.message)
        setRefresh(!refresh)
        setRowSelection({})
      }
    })
    .catch(function (error) {
      alert(error)
    })
}
