import {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {useAuth} from '../../../auth'
import {useCustPendingDepStore} from './Cust-Pending-Deposit-Store'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {searching} from '../../../components/Searching'
import {sortByName} from '../../../components/SortByName'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {Overlay} from '../../../components/Overlay'
import sortByNumber from '../../../components/SortByAmount'

export function CustomerPendingDeposit_Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  //Zustand Store
  const filterOptions = useCustPendingDepStore((state: any) => state.filterOptions)
  const getData = useCustPendingDepStore((state: any) => state.getData)
  const setFilterOptions = useCustPendingDepStore((state: any) => state.setFilterOptions)
  const setGetData = useCustPendingDepStore((state: any) => state.setGetData)

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    range: filterOptions.range ? String(filterOptions.range) : 'ALL',
    timezone: filterOptions.timezone ? String(filterOptions.timezone) : 'GMT+6',
    amount: filterOptions.amount ? String(filterOptions.amount) : '',
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
    range: Yup.string(),
    timezone: Yup.string(),
    amount: Yup.string(),
  })

  const keys = ['customerCd']

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let search = values.search.trim()
      let range = values.range.trim()
      let timezone = values.timezone.trim()

      setFilterOptions(values)

      axios
        .post(`${SISPAY_API}/cash/listpendingdeposit/count`, {
          request: {
            session: session,
            range: range, // ALL or TODAY
            timezone: timezone, // OPTIONAL : default GMT+6
          },
        })
        .then(function (response) {
          if (response.data.code == 200) {
            let filteredFormData = response.data.result.depositRequestList

            if (filteredFormData.length === 0) {
              setFormData([])
              setDisabled(false)
              setLoading(false)
              return
            }

            if (search !== '') {
              filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
            }

            if (values.amount !== '') {
              filteredFormData = filteredFormData.filter((item: any) => {
                return parseFloat(item.requestCount) >= parseFloat(values.amount)
              })
            }

            setFormData(sortByNumber(filteredFormData, 'requestCount'))
            setDisabled(false)
            setLoading(false)
          } else {
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {
          alert(error)
        })
    },
  })

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      {' '}
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                        {Overlay('SEARCH Customer Code')}
                      </div>
                      <input
                        placeholder='Enter Customer Code'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('search')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.search && formik.errors.search,
                          },
                          {
                            'is-valid': formik.touched.search && !formik.errors.search,
                          }
                        )}
                      />
                      {formik.touched.search && formik.errors.search && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.search}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col'>
                      {' '}
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Amount {'>'}=</label>
                        {Overlay('Filter customer pending deposit that more than ...')}
                      </div>
                      <input
                        placeholder='Enter Amount'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('amount')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.amount && formik.errors.amount,
                          },
                          {
                            'is-valid': formik.touched.amount && !formik.errors.amount,
                          }
                        )}
                      />
                      {formik.touched.amount && formik.errors.amount && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.amount}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col'>
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Range</label>
                        <select
                          {...formik.getFieldProps('range')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value='ALL'>ALL</option>
                          <option value='TODAY'>TODAY</option>
                        </select>
                      </div>{' '}
                    </div>
                    <div className='col'>
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Timezone</label>
                        <select
                          {...formik.getFieldProps('timezone')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value='GMT+6'>GMT+6</option>
                          <option value='GMT+7'>GMT+7</option>
                          <option value='GMT+8'>GMT+8</option>
                        </select>{' '}
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() =>
                        formik.resetForm({
                          values: {
                            search: '',
                            range: 'ALL',
                            timezone: 'GMT+6',
                            amount: '',
                          },
                        })
                      }
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}
